/* eslint-disable consistent-return */
import { NgKLoggerService } from '@kin/ngk-logger';
import { patchState, signalStoreFeature, type, withMethods } from '@ngrx/signals';
import type { LegacyApiPagesComponent, LegacyQuotePagesPath } from 'app/common/services/legacy/quote-pages';
import { getComponentsForLegacyPagesApiAboutYou } from 'app/create-quote/forms/about-you/about-you-utils';
import { getOptionsForLegacyPagesApiCoApplicantComponent } from 'app/create-quote/forms/co-applicant/co-applicant-utils';
import { getOptionsForLegacyPagesApiInsuranceStatusComponent } from 'app/create-quote/forms/insurance-status/insurance-status-utils';
import { InsuranceStatusRadioButtonOption } from 'app/create-quote/forms/insurance-status/insurance-status.types';
import { getComponentsForLegacyPagesApiPolicyStartDate } from 'app/create-quote/forms/policy-start-date/policy-start-date-utils';
import { getOptionsForLegacyPagesApiPolicyTypeComponent } from 'app/create-quote/forms/policy-type/policy-type-utils';
import { PolicyTypeRadioButtonOption } from 'app/create-quote/forms/policy-type/policy-type.types';
import { ConstructionTypeRadioButtonOption } from 'app/create-quote/forms/property-details/construction-type/construction-type.types';
import { getOptionsForLegacyPagesApiConstructionTypeComponent } from 'app/create-quote/forms/property-details/construction-type/construction-type.utils';
import { getOptionsForLegacyPagesApiExteriorWallComponent } from 'app/create-quote/forms/property-details/exterior-wall/exterior-wall.utils';
import { FoundationTypeRadioButtonOption } from 'app/create-quote/forms/property-details/foundation/foundation.types';
import { getOptionsForLegacyPagesApiFoundationTypeComponent } from 'app/create-quote/forms/property-details/foundation/foundation.utils';
import { getOptionsForLegacyPagesApiPropertyTypeComponent } from 'app/create-quote/forms/property-details/property-type/property-type-utils';
import { PropertyTypeRadioButtonOption } from 'app/create-quote/forms/property-details/property-type/property-type.types';
import { getOptionsForLegacyPagesApiRoofShapeComponent } from 'app/create-quote/forms/property-details/roof-shape/roof-shape-utils';
import { RoofShapeRadioButtonOption } from 'app/create-quote/forms/property-details/roof-shape/roof-shape.types';
import { getValidationsForLegacyPagesAPIRoofYearComponent } from 'app/create-quote/forms/property-details/roof-year/roof-year-utils';
import { RoofYearTypeValidations } from 'app/create-quote/forms/property-details/roof-year/roof-year.types';
import { YearBuiltFormOption } from 'app/create-quote/forms/property-details/year-built/year-built.types';
import { getOptionsForLegacyPagesApiYearBuiltComponent } from 'app/create-quote/forms/property-details/year-built/year-built.utils';
import { getOptionsForLegacyPagesApiPropertyInformationComponent } from 'app/create-quote/forms/property-information/property-information.utils';
import { getOptionsForLegacyPagesApiRentalNumberComponent } from 'app/create-quote/forms/rental-number/rental-number-utils';
import { RentalNumberSelectOption } from 'app/create-quote/forms/rental-number/rental-number.types';
import { getOptionsForLegacyPagesApiRentalStatusComponent } from 'app/create-quote/forms/rental-status/rental-status-utils';
import { getOptionsForLegacyPagesApiResidencyComponent } from 'app/create-quote/forms/residency/residency-utils';
import { ResidencyRadioButtonOption } from 'app/create-quote/forms/residency/residency.types';
import { getOptionsForLegacyPagesApiUnitNumberCheckComponent } from 'app/create-quote/forms/unit-number-check/unit-number-check-utils';
import { getOptionsForLegacyPagesApiUnitNumberEntryComponent } from 'app/create-quote/forms/unit-number-entry/unit-number-entry-utils';
import { mapLegacyPathToFormKey } from '../../../create-quote/form-config/map-legacy-path-to-form-key';
import { QuoteFormOptionsState } from '../quote-form-options.model';
import { quoteFormOptionsInitialState } from '../quote-form-options-initial-state';

type LegacyOptions =
  | PolicyTypeRadioButtonOption[]
  | InsuranceStatusRadioButtonOption[]
  | RentalNumberSelectOption[]
  | ResidencyRadioButtonOption[]
  | PropertyTypeRadioButtonOption[]
  | RoofShapeRadioButtonOption[]
  | YearBuiltFormOption[]
  | ConstructionTypeRadioButtonOption[]
  | FoundationTypeRadioButtonOption[]
  | RoofYearTypeValidations;

export function withQuoteFormOptionsMethods() {
  return signalStoreFeature(
    { state: type<QuoteFormOptionsState>() },
    withMethods((store) => ({
      reset(): void {
        patchState(store, () => quoteFormOptionsInitialState);
      },
      updateOptionsForFormByLegacyPath(legacyPath: LegacyQuotePagesPath, options: LegacyOptions | undefined, logger: NgKLoggerService): void {
        const formKey = mapLegacyPathToFormKey(legacyPath);
        if (!formKey) {
          logger.error({ message: `unable to map legacy path to form key: ${formKey}`, context: 'updateOptionsForFormByLegacyPath', priority: 'P3' });
          return;
        }
        patchState(store, () => ({ [formKey]: options }));
      },
      getLegacyOptionsForForm(legacyPath: LegacyQuotePagesPath, components: LegacyApiPagesComponent[] | undefined, logger: NgKLoggerService): LegacyOptions | undefined {
        const formKey = mapLegacyPathToFormKey(legacyPath);
        if (!formKey) {
          logger.error({ message: `unable to map legacy path to form key: ${formKey}`, context: 'getLegacyOptionsForForm', priority: 'P3' });
          return;
        }
        if (!components) {
          logger.error({ message: `components for formKey ${formKey} is undefined`, context: 'getLegacyOptionsForForm', priority: 'P3' });
          return;
        }

        switch (formKey) {
          case 'about-you':
            return getComponentsForLegacyPagesApiAboutYou(components, logger);
          case 'policy-type':
            return getOptionsForLegacyPagesApiPolicyTypeComponent(components, logger);
          case 'unit-number-check':
            return getOptionsForLegacyPagesApiUnitNumberCheckComponent(components, logger);
          case 'unit-number-entry':
            return getOptionsForLegacyPagesApiUnitNumberEntryComponent(components, logger);
          case 'insurance-status':
            return getOptionsForLegacyPagesApiInsuranceStatusComponent(components, logger);
          case 'rental-status':
            return getOptionsForLegacyPagesApiRentalStatusComponent(components, logger);
          case 'rental-number':
            return getOptionsForLegacyPagesApiRentalNumberComponent(components, logger);
          case 'residency':
            return getOptionsForLegacyPagesApiResidencyComponent(components, logger);
          case 'policy-start-date':
            return getComponentsForLegacyPagesApiPolicyStartDate(components, logger);
          case 'co-applicant':
            return getOptionsForLegacyPagesApiCoApplicantComponent(components, logger);
          case 'property-type':
            return getOptionsForLegacyPagesApiPropertyTypeComponent(components, logger);
          case 'roof-shape':
            return getOptionsForLegacyPagesApiRoofShapeComponent(components, logger);
          case 'roof-year':
            return getValidationsForLegacyPagesAPIRoofYearComponent(components, logger);
          case 'year-built':
            return getOptionsForLegacyPagesApiYearBuiltComponent(components, logger);
          case 'construction-type':
            return getOptionsForLegacyPagesApiConstructionTypeComponent(components, logger);
          case 'exterior-wall':
            return getOptionsForLegacyPagesApiExteriorWallComponent(components, logger);
          case 'property-information':
            return getOptionsForLegacyPagesApiPropertyInformationComponent(components, logger);
          case 'foundation':
            return getOptionsForLegacyPagesApiFoundationTypeComponent(components, logger);
          default:
            logger.error({ message: `unknown formKey ${formKey}`, context: 'getLegacyOptionsForForm', priority: 'P3' });
            return undefined;
        }
      },
    })),
  );
}
